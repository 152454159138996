<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card class="mt-2">
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="搜索关键字" label-width="100px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称/编码" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1 flex align-center">
				<el-button type="primary" icon="el-icon-view" size="mini" @click="handleView">查看</el-button>
				<el-button type="primary" icon="el-icon-download" size="mini" @click="downloadExcel">导出</el-button>
				<div class="ml-auto">
					<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
				</div>
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
				  </template>
				</el-table-column>
				<el-table-column label="姓名" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.t_name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="编码" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.t_code }}
				  </template>
				</el-table-column>
				<el-table-column label="年级" min-width="100" align="center">
				  <template slot-scope="scope">
					{{ scope.row.nianji }}
				  </template>
				</el-table-column>
				<el-table-column label="学科" min-width="100" align="center">
				  <template slot-scope="scope">
					{{ scope.row.xueke }}
				  </template>
				</el-table-column>
				<el-table-column label="获奖名称" min-width="150" align="center">
				  <template slot-scope="scope">
					{{ scope.row.title ||  ''}}
				  </template>
				</el-table-column>
				<el-table-column label="获奖类型" min-width="150" align="center">
				  <template slot-scope="scope">
					{{ scope.row.type_name ||  ''}}
				  </template>
				</el-table-column>
				<el-table-column label="分数" min-width="160" align="center">
				  <template slot-scope="scope">
					{{ scope.row.score }}
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="140">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.ctime }}</span>
				  </template>
				</el-table-column>
<!-- 				<el-table-column label="操作" min-width="100" align="center" v-if="isFinish">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column> -->
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.size"
				@pagination="query"
			 />
		</el-card>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	import Breadcrumb from '@/components/Breadcrumb';
	import dayjs from 'dayjs'
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
			Breadcrumb,
		},
		data() {
			return {
				preUrl: 'jsry_list',
				id:'',
				s_id:'',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
					role_id:this.VueCookies.get('role_id'),
				},
				nianji:[
					{id:1,name:'高一'},
					{id:2,name:'高二'},
					{id:3,name:'高三'},
				],
			}
		},
		mounted() {
			if(this.$route.query.id){
				this.id = this.$route.query.id
			}
			if(this.$route.query.id){
				this.s_id = this.$route.query.s_id
			}
		},
		computed:{
			isFinish(){
				if(this.list.length > 0 && this.list[0].is_finish == 2){
					return true
				}else{
					return false
				}
			}
		},
		methods:{
			handleView(row){
				this.$router.push({
				  path:"/jsry_view",
				  query: {}
				})
			},
			downloadExcel(){
				this.axios.get('/manage/jsry/downloadExcel',{
					responseType: 'arraybuffer',
					params:{
	
					}
				}).then(res=>{
					console.log(res)
					  let dayjsTime = dayjs(`${new Date()}`).format('YYYY-MM-DD')
					  const aLink = document.createElement("a");
					  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
					  aLink.href = URL.createObjectURL(blob);
					  aLink.download = '教师荣誉_'+dayjsTime+'.xlsx';
					  aLink.click();
					  document.body.appendChild(aLink);
				})
			},
		}
	}
</script>

<style>
</style>